<template>
  <template v-if="editing">
    <template v-if="editing">
      <template v-for="(color, index) in colorways" :key="color">
        <span class="ui label" :style="`background-color: ${getColorFromId(type, color).RGB}; border-color: ${getColorFromId(type, color).RGB};`">
          <span v-if="!getColorFromId(type, color).RGB" class="color-custom">n/a</span>
        </span>
        {{ index + 1 }}. {{ getColorFromId(type, color).name }} <i @click="removeColor(index)" class="close icon"></i><br />
      </template>
    </template>
  </template>

  <template v-else>
    <template v-for="(color, index) in colorways" :key="color">
      <span class="ui label" :style="`background-color: ${getColorFromId(type, color).RGB}; border-color: ${getColorFromId(type, color).RGB};`">
        <span v-if="!getColorFromId(type, color).RGB" class="color-custom">n/a</span>
      </span>
      {{ index + 1 }}. {{ getColorFromId(type, color).name }}<br />
    </template>
  </template>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["modelValue", "type", "editing"],

  computed: {
    colorways: {
      get() {
        return JSON.parse(this.modelValue)
      },

      set(value) {
        this.$emit("update:modelValue", JSON.stringify(value));
      }
    },

    ...mapState(["inks", "threads", "vinyls"])
  },

  methods: {
    removeColor(index) {
      // Computed get() isn't reacting to colorways.splice()
      // Copy and replace works
      let copy = this.colorways;
      copy.splice(index, 1);
      this.colorways = copy;
    },

    getColorFromId(type, id) {
      switch (type) {
        case "SC":
          return this.inks.find(i => i.id === id) || { id, name: id };
        case "EM":
          return this.threads.find(i => i.id === id) || { id, name: id };
        case "VI":
          return this.vinyls.find(i => i.id === id) || { id, name: id };
        case "ND":
          return this.threads.find(i => i.id === id) || { id, name: id };
        case "NV":
          return this.vinyls.find(i => i.id === id) || { id, name: id };
      }
    }
  }
};
</script>

<style scoped>
.color-custom {
  position: absolute;
  margin-top: -0.5em;
  margin-left: -0.6em;
}
</style>