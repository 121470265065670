<template>
  <div class="ui raised segment">
    <h1>Pricing Summary</h1>
    <table class="ui three column table">
      <thead>
        <tr>
          <th>Service</th>
          <th>Items</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Proof</td>
          <td>{{ proofItems }}</td>
          <td>{{ proofPrice.format() }}</td>
        </tr>
        <tr>
          <td>Creative Art</td>
          <td>{{ creativeArtItems }}</td>
          <td>{{ creativeArtPrice.format() }}</td>
        </tr>
        <tr>
          <td>Vectorization</td>
          <td>{{ vectorizationItems }}</td>
          <td>{{ vectorizationPrice.format() }}</td>
        </tr>
        <tr>
          <td>Digitizing</td>
          <td>{{ digitizingItems }}</td>
          <td>{{ digitizingPrice.format() }}</td>
        </tr>
        <tr>
          <td>Vinyl</td>
          <td>{{ vinylItems }}</td>
          <td>{{ vinylPrice.format() }}</td>
        </tr>
        <tr>
          <td>Names - Embroidery</td>
          <td>{{ namesDigitizingItems }}</td>
          <td>{{ namesDigitizingPrice.format() }}</td>
        </tr>
        <tr>
          <td>Names and Numbers - Vinyl</td>
          <td>{{ namesVinylItems }}</td>
          <td>{{ namesVinylPrice.format() }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th v-if="order.rush === 'R'">Subtotal</th>
          <th v-else>Total</th>
          <th></th>
          <th>{{ subtotal.format() }}</th>
        </tr>
        <tr v-if="order.rush === 'R'">
          <th>Rush (30%)</th>
          <th></th>
          <th>{{ rush.format() }}</th>
        </tr>
        <tr v-if="order.rush === 'R'">
          <th>Total</th>
          <th></th>
          <th>{{ total.format() }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
  <br>
  <br>
</template>

<script>
import currency from "currency.js";
import { mapState } from 'vuex';

export default {
  props: {
    order: Object
  },

  computed: {
    proofItems () {
      return this.order.GarmentOrder.length + this.order.PromotionalProductOrder.length;
    },

    proofPrice () {
      const base = currency(this.profile.service_prices.find(p => p.service === 'PR').price);
      if (this.proofItems) {
        return base;
      }
      return currency(0);
      // let extra = this.order.GarmentOrder.length + this.order.PromotionalProductOrder.length - 3;
      // if (extra > 0) {
      //   return base.add(currency(this.profile.service_prices.find(p => p.service === 'PI').price).multiply(extra));
      // } else if (this.proofItems) {
      //   return base
      // } else {
      //   return currency(0);
      // }
    },

    creativeArtItems () {
      return this.order.ArtworkOrder.filter(a => a.is_active && a.creativeart).length;
    },

    creativeArtPrice () {
      let price = currency(0);
      
      this.order.ArtworkOrder.filter(a => a.is_active && a.creativeart).forEach(a => {
        switch (a.creativeart.complexity) {
          case 'S':
            price = price.add(currency(this.profile.service_prices.find(p => p.service === 'CR' && p.tier === 'S').price));
            break;
          case 'M':
            price = price.add(currency(this.profile.service_prices.find(p => p.service === 'CR' && p.tier === 'M').price));
            break;
          case 'L':
            price = price.add(currency(this.profile.service_prices.find(p => p.service === 'CR' && p.tier === 'L').price));
            break;
          case 'XL':
            price = price.add(currency(this.profile.service_prices.find(p => p.service === 'CR' && p.tier === 'XL').price));
            break;
        }
      });
      
      return price;
    },

    digitizingItems () {
      let base = this.order.ArtworkOrder.filter(a => a.is_active && a.digitizing && !a.digitizing.print_ready).length;
      let from_creative = 0;

      this.order.ArtworkOrder.filter(a => a.is_active && a.creativeart).forEach(a => {
        if (a.creativeart.needs_digitizing_h) {
          from_creative++;
        }
        if (a.creativeart.needs_digitizing_s) {
          from_creative++;
        }
        if (a.creativeart.needs_digitizing_m) {
          from_creative++;
        }
        if (a.creativeart.needs_digitizing_l) {
          from_creative++;
        }
        if (a.creativeart.needs_digitizing_xl) {
          from_creative++;
        }
      });

      return base + from_creative;
    },

    digitizingPrice () {
      let base = currency(0);
      let from_creative = currency(0);

      this.order.ArtworkOrder.filter(a => a.is_active && a.digitizing && !a.digitizing.print_ready).forEach(a => {
        if (a.digitizing.size === 'H') {
          base = base.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price));
        } else {
          base = base.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === a.digitizing.size).price));
        }
      });
      
      this.order.ArtworkOrder.filter(a => a.is_active && a.creativeart).forEach(a => {
        if (a.creativeart.needs_digitizing_h) {
          from_creative = from_creative.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price));
        }
        if (a.creativeart.needs_digitizing_s) {
          from_creative = from_creative.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price));
        }
        if (a.creativeart.needs_digitizing_m) {
          from_creative = from_creative.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === 'M').price));
        }
        if (a.creativeart.needs_digitizing_l) {
          from_creative = from_creative.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === 'L').price));
        }
        if (a.creativeart.needs_digitizing_xl) {
          from_creative = from_creative.add(currency(this.profile.service_prices.find(p => p.service === 'DI' && p.tier === 'XL').price));
        }
      });
      
      return base.add(from_creative);
    },

    vectorizationItems () {
      return this.order.ArtworkOrder.filter(a => a.is_active && a.vector && !a.vector.print_ready).length;
    },

    vectorizationPrice () {
      return currency(this.profile.service_prices.find(p => p.service === 'VE').price).multiply(this.vectorizationItems);
    },

    vinylItems () {
      return this.order.ArtworkOrder.filter(a => a.is_active && a.vinyl && !a.vinyl.print_ready).length;
    },

    vinylPrice () {
      return currency(this.profile.service_prices.find(p => p.service === 'VI').price).multiply(this.vinylItems);
    },

    namesDigitizingItems () {
      let items = 0;
      this.order.ArtworkOrder.filter(a => a.is_active && a.namesdigitizing).forEach(a => {
        items += a.namesdigitizing.count;
      })
      return items;
    },

    namesDigitizingPrice () {
      return currency(this.profile.service_prices.find(p => p.service === 'ND').price).multiply(this.namesDigitizingItems);
    },

    namesVinylItems () {
      return this.order.ArtworkOrder.filter(a => a.is_active && a.namesvinyl).length;
    },

    namesVinylPrice () {
      return currency(this.profile.service_prices.find(p => p.service === 'NV').price).multiply(this.namesVinylItems);
    },

    subtotal () {
      let subtotal = this.creativeArtPrice.add(this.digitizingPrice).add(this.vectorizationPrice).add(this.namesDigitizingPrice).add(this.namesVinylPrice);
      if (this.order.needs_proof) {
        return subtotal.add(this.proofPrice);
      } else {
        return subtotal;
      }
    },

    rush () {
      return this.subtotal.multiply(this.profile.service_prices.find(p => p.service === 'R').price);
    },

    total () {
      return this.subtotal.add(this.rush);
    },

    ...mapState(['profile'])
  }
}
</script>
