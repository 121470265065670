<template>
  <div class="ui form" v-if="editing">
    <br />
    <h1>Add art</h1>

    <div class="field">
      <label>Type</label>
      <select id="newArtworkType" v-model="newArtwork.type" class="ui dropdown">
        <option value="">Select one</option>
        <option value="CR">Creative Art</option>
        <option value="P" :disabled="order.needs_proof">Proof</option>
        <option value="VE">Vector File</option>
        <option value="DI">Embroidery File</option>
        <option value="VI">Vinyl</option>
        <option value="ND">Names - Embroidery</option>
        <option value="NV">Names and Numbers - Vinyl</option>
      </select>
    </div>

    <template v-if="newArtwork.type">
      <div v-show="newArtwork.type !== 'P'">
        <div class="field">
          <label>Design ID</label>
          <input v-model="newArtwork.code" placeholder="Code" />
        </div>
      </div>

      <div v-show="newArtwork.type === 'VE'" class="field">
        <label>Complexity</label>
        <p>Unique (${{ profile.service_prices.find(p => p.service === 'VE').price }}) (Rush adds 30%)</p> 
      </div>

      <div v-show="newArtwork.type === 'P'" class="field">
        <label>Complexity</label>
        <p>Unique (${{ profile.service_prices.find(p => p.service === 'PR').price }}) (Rush adds 30%)</p> 
      </div>

      <div v-show="newArtwork.type === 'DI'" class="field">
        <label>Size</label>
        <select id="newArtworkSize" v-model="newArtwork.size">
          <option value="H">Hat (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price }}) (Rush adds 30%)</option>
          <option value="S">Left Chest (Up to 3.5x3.5 in.) (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price }}) (Rush adds 30%)</option>
          <option value="M">Bag (Up to 5x5 in.) (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'M').price }}) (Rush adds 30%)</option>
          <option value="L">Back/Chest (Up to 10 wide x 5 tall in.) (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'L').price }}) (Rush adds 30%)</option>
          <option value="XL">Full Back (Up to 10x10 in.) (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'XL').price }}) (Rush adds 30%)</option>
        </select>
      </div>

      <div v-show="newArtwork.type === 'CR'" class="field">
        <label>Complexity</label>
        <select id="newArtworkComplexity" v-model="newArtwork.complexity">
          <option value="S">Simple (${{ profile.service_prices.find(p => p.service === 'CR' && p.tier === 'S').price }}) (Rush adds 30%)</option>
          <option value="M">Medium (${{ profile.service_prices.find(p => p.service === 'CR' && p.tier === 'M').price }}) (Rush adds 30%)</option>
          <option value="L">Complex (${{ profile.service_prices.find(p => p.service === 'CR' && p.tier === 'L').price }}) (Rush adds 30%)</option>
          <option value="XL">Very Complex (${{ profile.service_prices.find(p => p.service === 'CR' && p.tier === 'XL').price }}) (Rush adds 30%)</option>
        </select>

        <label>Inspirational Reference URL</label>
        <input v-model="newArtwork.inspirational_reference_url" />

        <label>Inspirational Reference Files</label>
        <p>First upload this art job to attach reference files.</p>

        <div class="field">
          <label>Required digitizings</label>
          <div class="inline fields">
            <div class="field">
              <div class="ui checkbox">
                <input id="needs_digitizing_h" type="checkbox" v-model="newArtwork.needs_digitizing_h"/>
                <label for="needs_digitizing_h">Hat (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price }})</label>
              </div>
            </div>
            <div class="field">
              <div class="ui checkbox">
                <input id="needs_digitizing_s" type="checkbox" v-model="newArtwork.needs_digitizing_s"/>
                <label for="needs_digitizing_s">Small (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'S').price }})</label>
              </div>
            </div>
            <div class="field">
              <div class="ui checkbox">
                <input id="needs_digitizing_m" type="checkbox" v-model="newArtwork.needs_digitizing_m"/>
                <label for="needs_digitizing_m">Medium (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'M').price }})</label>
              </div>
            </div>
            <div class="field">
              <div class="ui checkbox">
                <input id="needs_digitizing_l" type="checkbox" v-model="newArtwork.needs_digitizing_l"/>
                <label for="needs_digitizing_l">Large (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'L').price }})</label>
              </div>
            </div>
            <div class="field">
              <div class="ui checkbox">
                <input id="needs_digitizing_xl" type="checkbox" v-model="newArtwork.needs_digitizing_xl"/>
                <label for="needs_digitizing_xl">Extra large (${{ profile.service_prices.find(p => p.service === 'DI' && p.tier === 'XL').price }})</label>
              </div>
            </div>
          </div>
        </div>
        (Rush adds 30%)
      </div>

      <div v-show="newArtwork.type !== 'P' && newArtwork.type !== 'CR' && newArtwork.type !== 'ND' && newArtwork.type !== 'NV'">
        <div class="ui form">
          <div class="field">
            <div class="ui checkbox">
              <input
                id="print_ready"
                v-model="newArtwork.print_ready"
                type="checkbox"
              />
              <label for="print_ready" label>Print ready (no conversion required, service price won't be charged)</label>
            </div>
          </div>
        </div>
      </div>
      <br>

      <div
        v-show="newArtwork.type === 'VE' && !newArtwork.print_ready"
        class="field"
      >
        <label>Format</label>
        <select class="ui dropdown" v-model="newArtwork.format">
          <option value="AI">AI</option>
          <option value="EPS">EPS</option>
          <option value="PDF">PDF</option>
        </select>
      </div>

      <div
        v-show="newArtwork.type === 'VI' && !newArtwork.print_ready"
        class="field"
      >
        <label>Colors</label>
        <select class="ui dropdown" v-model="newArtwork.color">
          <option value="F">Full Color</option>
          <option value="S">Single Color</option>
        </select>
      </div>

      <template v-if="newArtwork.type === 'ND'">
        <div class="field">
          <label>Count (number of names in Excel file)</label>
          <input v-model="newArtwork.count" placeholder="Count" type="number" />
        </div>
        <div class="field">
          <label>Name height (inches, blank for 1" default)</label>
          <input v-model="newArtwork.name_height" placeholder="Name height" />
        </div>
        <div class="field">
          <label>Font (blank for default)</label>
          <input v-model="newArtwork.name_font" placeholder="Font" />
        </div>
      </template>

      <template v-else-if="newArtwork.type === 'NV'">
        <div class="field">
          <label>Roll width (inches, blank for 20" default)</label>
          <input v-model="newArtwork.roll_width" placeholder="Roll width" />
        </div>
        <div class="field">
          <label>Name height (inches, blank for 2" default)</label>
          <input v-model="newArtwork.name_height" placeholder="Name height" />
        </div>
        <div class="field">
          <label>Number height (inches, blank for 8" default)</label>
          <input v-model="newArtwork.number_height" placeholder="Number height" />
        </div>
        <div class="field">
          <label>Name font (blank for default)</label>
          <input v-model="newArtwork.name_font" placeholder="Name font" />
        </div>
        <div class="field">
          <label>Number font (blank for default)</label>
          <input v-model="newArtwork.number_font" placeholder="Number font" />
        </div>
      </template>

      <!-- <div
        v-if="newArtwork.type === 'DI' && newArtwork.print_ready"
        class="inline fields"
      >
        <div class="field">
          <label>Width</label>
          <input v-model="newArtwork.width" />
        </div>
        <div class="field">
          <label>Height</label>
          <input v-model="newArtwork.height" />
        </div>
        <div class="field">
          <label>Stitches</label>
          <input v-model="newArtwork.stitches" />
        </div>
      </div> -->

      <!-- <div class="field" v-show="newArtwork.type !== 'P' && newArtwork.type !== 'CR'"> -->
      <div class="field" v-show="newArtwork.type !== 'P'">
        <label v-if="newArtwork.type === 'DI'">Embroidery File</label>
        <label v-else>File</label>
        <input
          id="uploadField"
          name="uploadField"
          type="file"
          :disabled="
            !newArtwork.code ||
              !newArtwork.type ||
              (newArtwork.type === 'VE' &&
                !newArtwork.print_ready &&
                !newArtwork.format) ||
              (newArtwork.type === 'DI' && !newArtwork.size)"
        />
      </div>

      
      <div v-if="newArtwork.type === 'DI' && newArtwork.print_ready" class="field">
        <label>Reference Image File</label>
        <input
          id="imageFile"
          name="imageFile"
          type="file"
        />
      </div>
      <button v-if="newArtwork.type !== 'CR'" class="ui button" @click="fileUpload('uploadField')">
        Upload Art File
      </button>
      <button
        v-else-if="newArtwork.type === 'CR'"
        class="ui button"
        @click="submitCreativeArt"
      >
        Upload Art Job
      </button>

      <div v-if="newArtwork.type === 'P'">
        <button
          v-if="!order.needs_proof"
          @click="$emit('proof-added')"
          class="ui button"
        >
          Add proof
        </button>
        <p v-else>The proof job is already on this order.</p>
      </div>
    </template>
  </div>
</template>

<script>
import formErrorHandler from "@/utils/formErrorHandler";
import { mapState } from "vuex";

const $ = window.jQuery;

export default {
  props: ["editing", "order"],

  data() {
    return {
      newArtwork: {
        code: null,
        type: null,
        inspirational_reference_url: null,
        size: null,
        complexity: null,
        needs_digitizing_h: false,
        needs_digitizing_s: false,
        needs_digitizing_m: false,
        needs_digitizing_l: false,
        needs_digitizing_xl: false
      }
    };
  },

  computed: {
    ...mapState(['profile'])
  },

  methods: {
    fileUpload(field, files) {
      files = document.getElementById(field).files;
      const file = files[0];

      let fileItem = {
        code: this.newArtwork.code,
        filename_original: file.name,
        progress: 0,
        print_ready: this.newArtwork.print_ready,
        digitizing: this.newArtwork.type === "DI" ? true : false
        // width:
        //   this.newArtwork.type === "DI" && this.newArtwork.print_ready
        //     ? this.newArtwork.width
        //     : null,
        // height:
        //   this.newArtwork.type === "DI" && this.newArtwork.print_ready
        //     ? this.newArtwork.height
        //     : null,
        // stitches:
        //   this.newArtwork.type === "DI" && this.newArtwork.print_ready
        //     ? this.newArtwork.stitches
        //     : null
      };
      this.$emit("uploading", fileItem);
      // this.files.push(fileItem);

      let formData = new FormData();
      formData.append("filename", file);
      formData.append("filename_original", file.name);
      formData.append("code", this.newArtwork.code);
      formData.append("order", this.order.id);
      formData.append(
        "print_ready",
        this.newArtwork.print_ready ? true : false
      );

      let url;
      if (this.newArtwork.type === "VE") {
        url = "api/v1/vectors/";
        if (!this.newArtwork.print_ready) {
          formData.append("format", this.newArtwork.format);
        }
      } else if (this.newArtwork.type === "DI") {
        url = "api/v1/digitizings/";
        formData.append("format", "DS");
        formData.append("size", this.newArtwork.size);
        if (this.newArtwork.print_ready) {
          if (document.getElementById('imageFile').files.length) {
            const imageFile = document.getElementById('imageFile').files[0];
            formData.append("image_file", imageFile);
          }
        //   formData.append(
        //     "width",
        //     this.newArtwork.width ? this.newArtwork.width : ""
        //   );
        //   formData.append(
        //     "height",
        //     this.newArtwork.height ? this.newArtwork.height : ""
        //   );
        //   formData.append(
        //     "stitches",
        //     this.newArtwork.stitches ? this.newArtwork.stitches : ""
        //   );
        }
      } else if (this.newArtwork.type === 'VI') {
        url = "api/v1/vinyls/";
        formData.append("color", this.newArtwork.color);
      } else if (this.newArtwork.type === 'ND') {
        url = "api/v1/names-digitizing/";
        formData.append("count", this.newArtwork.count);
        if (this.newArtwork.name_height) {
          formData.append("name_height", this.newArtwork.name_height);
        }
        if (this.newArtwork.name_font) {
          formData.append("name_font", this.newArtwork.name_font);
        }
      } else if (this.newArtwork.type === 'NV') {
        url = "api/v1/names-vinyl/";
        if (this.newArtwork.roll_width) {
          formData.append("roll_width", this.newArtwork.roll_width);
        }
        if (this.newArtwork.name_height) {
          formData.append("name_height", this.newArtwork.name_height);
        }
        if (this.newArtwork.number_height) {
          formData.append("number_height", this.newArtwork.number_height);
        }
        if (this.newArtwork.name_font) {
          formData.append("name_font", this.newArtwork.name_font);
        }
        if (this.newArtwork.number_font) {
          formData.append("number_font", this.newArtwork.number_font);
        }
      }

      this.$http
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            fileItem.progress =
              (progressEvent.loaded / progressEvent.total) * 100;
            $(".progress").progress();
          }
        })
        .then(() => {
          this.$emit("uploaded");

          this.newArtwork = {
            code: null,
            type: null
          };
          $(`#${field}`).val("");
          $(`#imageFile`).val("");
          $("#newArtworkType").dropdown("clear");
          $("#newArtworkSize").dropdown("clear");
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    submitCreativeArt() {
      this.$http
        .post("api/v1/creative-arts/", {
          code: this.newArtwork.code,
          order: this.order.id,
          complexity: this.newArtwork.complexity,
          inspirational_reference_url: this.newArtwork
            .inspirational_reference_url,
          needs_digitizing_h: this.newArtwork.needs_digitizing_h,
          needs_digitizing_s: this.newArtwork.needs_digitizing_s,
          needs_digitizing_m: this.newArtwork.needs_digitizing_m,
          needs_digitizing_l: this.newArtwork.needs_digitizing_l,
          needs_digitizing_xl: this.newArtwork.needs_digitizing_xl
        })
        .then(() => {
          this.$emit("uploaded");
          this.newArtwork.code = null;
          this.newArtwork.complexity = null;
          this.newArtwork.inspirational_reference_url = null;
          this.newArtwork.needs_digitizing_h = false;
          this.newArtwork.needs_digitizing_s = false;
          this.newArtwork.needs_digitizing_m = false;
          this.newArtwork.needs_digitizing_l = false;
          this.newArtwork.needs_digitizing_xl = false;
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    }
  },

  watch: {
    'newArtwork.type' (type) {
      if (type === 'CR') {
        this.newArtwork.code = `${this.order.reference_number}-${this.order.sequence}`
      } else {
        this.newArtwork.code = null
      }
    }
  }
};
</script>
