const $ = window.jQuery;

export default (formId, error) => {
  if (error.response?.status === 400) {
    if (typeof error.response.data === "string") {
      $(`#${formId}`).form("add errors", [error.response.data]);
      document.getElementById(formId).scrollIntoView();
      return;
    }

    const errors = [];
    const fields = error.response.data;

    for (let field in fields) {
      if (field !== "non_field_errors") {
        if (typeof fields[field] === "string") {
          errors.push(fields[field]);
        } else {
          const capitalizedField =
            field.charAt(0).toUpperCase() + field.slice(1);
          errors.push(`${capitalizedField}: ${fields[field][0]}`);
          $(`#${formId}`).form("add prompt", field);
        }
      } else {
        errors.push(fields[field][0]);
      }
    }

    $(`#${formId}`).form("add errors", errors);
  } else if (error.response?.status === 403) {
    $(`#${formId}`).form("add errors", [error.response.data.detail]);
  } else {
    if (error.response) {
      alert(JSON.stringify(error.response));
    } else {
      alert("An unexpected error has ocurred.");
    }
  }

  document.getElementById(formId).scrollIntoView();
};
