<template>
  <div class="ui container">
    <div v-if="profile.role !== 'S'" class="ui floating text menu">
      <menu-item
        v-if="!this.editing && !canProceed"
        :action="() => {}"
        classes="basic disabled"
        icon="warning"
        label="Add creative art or proof to proceed"
      ></menu-item>

      <menu-item
        v-if="canCreate"
        :action="onCreateClick"
        classes="green"
        icon="palette"
        label="Submit for art creation"
      ></menu-item>

      <menu-item
        v-if="canProof"
        :action="onProofClick"
        classes="green"
        icon="clipboard outline"
        label="Submit for proof"
      ></menu-item>

      <menu-item
        v-if="order?.status === 'DR' && !editing"
        :action="edit"
        classes="primary"
        icon="edit"
        label="Edit"
      ></menu-item>

      <menu-item
        v-if="editing"
        :action="submit"
        classes="primary"
        icon="mail"
        label="Next"
      ></menu-item>

      <menu-item
        v-if="editing"
        :action="discard"
        classes="secondary basic"
        icon="undo"
        label="Discard"
      ></menu-item>
    </div>

    <template v-if="profile.role !== 'S'">
      <br /><br /><br />
    </template>

    <order-header v-model:order="order" :editing="editing"></order-header>

    <div v-if="$route.params.id !== 'new'" class="ui raised segment">
      <h1>Art Files</h1>
      <template v-if="!editing && order?.ArtworkOrder.length === 0">
        <br />
        <p>This order has no artworks yet.</p>
        <br />
      </template>

      <div class="ui stackable cards">
        <div
          v-for="(creative_art, index) in creative_arts"
          :key="creative_art.id"
          class="ui card"
          :class="{ disabled: !creative_art.is_active }"
        >
          <div v-if="editing" style="padding: 0.5em 0.5em 0 0;">
            <div v-if="creative_art.is_active" class="right floated meta">
              <i
                @click="onCreativeDisableClick(creative_art)"
                class="close icon"
              ></i>
            </div>
            <div v-else class="right floated meta">
              <i
                @click="onCreativeEnableClick(creative_art)"
                class="redo icon"
              ></i>
            </div>
          </div>
          <div class="image">
            <img
              v-if="
                !creative_art.filename || creative_art.filename.endsWith('pdf')"
              src="/dfm.png"
            />
            <img v-else :src="artworkImage(creative_art.filename)" />
          </div>
          <div class="content">
            <a v-if="creative_art.filename" class="header" :href="creative_art.filename" target="_blank">
              {{ creative_art.code }}.pdf <i class="download icon" />
            </a>
            <p v-else class="header">{{ creative_art.code }}.pdf</p>
            <span v-if="!creative_art.print_ready" class="ui blue label">
              Needs conversion
            </span>
            <span v-else class="ui green label">
              Print ready
            </span>
            <div class="meta">
              <p>Creative art (vector)</p>
              <p v-if="creative_art.creativeart.complexity === 'S'">Simple</p>
              <p v-if="creative_art.creativeart.complexity === 'M'">Medium</p>
              <p v-if="creative_art.creativeart.complexity === 'L'">Complex</p>
              <p v-if="creative_art.creativeart.complexity === 'XL'">
                Very Complex
              </p>
              <p v-if="order?.status === 'DR' || order?.needs_proof">
                Digitizings:<br>
                <a v-if="creative_art.creativeart.needs_digitizing_h" class="ui tiny label">
                  H
                </a>
                <a v-if="creative_art.creativeart.needs_digitizing_s" class="ui tiny label">
                  S
                </a>
                <a v-if="creative_art.creativeart.needs_digitizing_m" class="ui tiny label">
                  M
                </a>
                <a v-if="creative_art.creativeart.needs_digitizing_l" class="ui tiny label">
                  L
                </a>
                <a v-if="creative_art.creativeart.needs_digitizing_xl" class="ui tiny label">
                  XL
                </a>
                <span
                  v-if="
                    !creative_art.creativeart.needs_digitizing_h &&
                      !creative_art.creativeart.needs_digitizing_s &&
                      !creative_art.creativeart.needs_digitizing_m &&
                      !creative_art.creativeart.needs_digitizing_l &&
                      !creative_art.creativeart.needs_digitizing_xl"
                >
                  None
                </span>
              </p>
              <p>
                <a
                  v-if="creative_art.creativeart.inspirational_reference_url"
                  :href="creative_art.creativeart.inspirational_reference_url"
                  target="_blank"
                >
                  Inspirational Reference Link
                </a>
              </p>
              <p>Inspirational Reference Files:</p>
              <p v-for="reference in creative_art.creativeart.InspirationalReferences" :key="reference.id">
                <a :style="{ 'text-decoration': !reference.is_active ? 'line-through' : '' }" :href="reference.filename" target="_blank">{{ reference.filename_original }}</a>
                <i v-if="editing && reference.is_active" @click="onInspirationalReferenceDisableClick(reference)" class="remove icon" />
                <i v-else-if="editing" @click="onInspirationalReferenceEnableClick(reference)" class="undo icon" />
              </p>
              <input
                type="file"
                style="display: none;"
                :name="`inspirational-file-${index}`"
                :id="`inspirational-file-${index}`"
                :ref="`inspirational-file-${index}`"
                @change="
                  onInspirationalFileAdd(
                    creative_art,
                    `inspirational-file-${index}`
                  )"
              />
              <p v-if="editing">
                <label :for="`inspirational-file-${index}`">
                  <a>Add <i class="plus icon"/></a>
                </label>
              </p>
            </div>
          </div>
          <div v-if="creative_art.filename" class="extra content">
            <div class="ui two buttons" v-if="creative_art.approved === null">
              <div
                @click="onCreativeApproveClick(creative_art)"
                class="ui basic tiny green button"
              >
                <i class="check icon"></i>
              </div>
              <div
                @click="onCreativeRejectClick(creative_art)"
                class="ui basic tiny red button"
              >
                <i class="ban icon"></i>
              </div>
            </div>
            <div
              v-else-if="creative_art.approved"
              class="ui basic tiny disabled fluid green button"
            >
              <i class="check icon"></i> Approved
            </div>
            <div v-else class="ui basic tiny disabled fluid red button">
              <i class="ban icon"></i> Rejected
            </div>
          </div>
        </div>

        <div v-if="order?.needs_proof" class="ui card">
          <div v-if="editing" class="content" style="padding: 0.5em 0.5em 0 0;">
            <div class="right floated meta">
              <i @click="order.needs_proof = false" class="ui close icon"></i>
            </div>
          </div>
          <div class="image">
            <img style="filter: grayscale(100%)" src="/proof.png" />
          </div>
          <div class="content">
            <p v-if="!order.proof" class="header">
              {{ order.reference_number }}-{{ order.sequence }}.pdf
            </p>
            <a v-else :href="order.proof.file" class="header" target="_blank">
              {{ order.reference_number }}-{{ order.sequence }}.pdf
              <i class="download icon" />
            </a>
            <span v-if="!order.proof" class="ui blue label">
              Needs conversion
            </span>
            <span v-else class="ui green label">
              Print ready
            </span>
            <div class="meta">
              <p>Proof</p>
            </div>
          </div>
          <div
            v-if="order.status !== 'DR' && order.status !== 'PR'"
            class="extra content"
          >
            <div
              v-if="order.proof?.approved === null"
              class="ui basic tiny disabled fluid black button"
            >
              <i class="clock icon"></i> Sent
            </div>
            <div
              v-else-if="order.proof?.approved"
              class="ui basic tiny disabled fluid green button"
            >
              <i class="check icon"></i> Approved
            </div>
            <div
              v-else-if="order.proof"
              class="ui basic tiny disabled fluid red button"
            >
              <i class="ban icon"></i> Rejected
            </div>
          </div>
        </div>

        <div
          v-for="artwork in artworks"
          :key="artwork.id"
          class="ui card"
          :class="{ disabled: !artwork.is_active }"
        >
          <div v-if="editing" style="padding: 0.5em 0.5em 0 0;">
            <div
              v-if="artwork.is_active"
              @click="onArtworkDisableClick(artwork)"
              class="right floated meta"
            >
              <i class="close icon"></i>
            </div>
            <div
              v-else
              @click="onArtworkEnableClick(artwork)"
              class="right floated meta"
            >
              <i class="redo icon"></i>
            </div>
          </div>
          <div class="image">
            <img v-if="artwork.digitizing?.image_file" :src="artwork.digitizing.image_file"/>
            <img v-else :src="artworkImage(artwork.filename)" />
          </div>
          <div class="content">
            <a
              v-if="artwork.print_ready"
              :href="artworkLink(artwork.filename)"
              class="header"
              target="_blank"
              :download="artwork.filename_original"
            >
              {{ artwork.filename_original }}
              <i class="download icon" />
            </a>
            <a
              v-else
              :href="artworkLink(artwork.filename)"
              class="header"
              target="_blank"
            >
              {{ artwork.filename_original }}
              <i class="download icon" />
            </a>
            <span v-if="artwork.print_ready" class="ui green label">
              Print ready
            </span>
            <span v-else class="ui blue label">
              Needs conversion
            </span>
            <div class="meta">
              <p>
                <template v-if="artwork.digitizing">Digitizing</template>
                <template v-else-if="artwork.vector">Vector</template>
                <template v-else-if="artwork.vinyl">
                  <template v-if="artwork.vinyl.color === 'F'">
                    Full Color
                  </template>
                  <template v-else>
                    Single Color
                  </template>
                  Vinyl
                </template>
                <template v-else-if="artwork.namesdigitizing">
                  Names - Embroidery
                </template>
                <template v-else-if="artwork.namesvinyl">
                  Names and numbers - Vinyl
                </template>
              </p>
              <template v-if="artwork.digitizing">
                <p v-if="artwork.digitizing.size === 'H'">Hat</p>
                <p v-if="artwork.digitizing.size === 'S'">Small</p>
                <p v-if="artwork.digitizing.size === 'M'">Medium</p>
                <p v-if="artwork.digitizing.size === 'L'">Large</p>
                <p v-if="artwork.digitizing.size === 'XL'">Extra Large</p>
              </template>
              <p v-if="!artwork.print_ready">Design ID: {{ artwork.code }}</p>
              <p v-if="artwork.namesdigitizing">{{ artwork.namesdigitizing.count }} names</p>
              <!-- <p
                v-if="
                  artwork.digitizing &&
                    artwork.print_ready &&
                    artwork.digitizing.width &&
                    artwork.digitizing.height"
              >
                {{ artwork.digitizing.width }}" x
                {{ artwork.digitizing.height }}"
              </p>
              <p
                v-if="
                  artwork.digitizing &&
                    artwork.print_ready &&
                    artwork.digitizing.stitches"
              >
                {{ artwork.digitizing.stitches }}
                stitches
              </p> -->
              <p v-if="artwork.converted_from">
                Converted from:
                <a :href="getConvertedFrom(artwork).filename" target="_blank">
                  {{ getConvertedFrom(artwork).filename_original }} <i class="download icon" />
                </a>
              </p>
            </div>
          </div>
          <div
            class="extra content"
            v-if="artwork.print_ready && artwork.converted_from"
          >
            <div
              class="ui two buttons"
              v-if="artwork.vector?.approved === null ||
                  artwork.digitizing?.approved === null"
            >
              <div
                @click="onArtworkRejectClick(artwork)"
                class="ui basic tiny red button"
              >
                <i class="ban icon"></i> Reject
              </div>
            </div>
            <div v-else class="ui basic tiny disabled fluid red button">
              <i class="ban icon"></i> Rejected
            </div>
          </div>
        </div>

        <div v-for="(file, index) in files" :key="index" class="ui card">
          <div class="image">
            <img src="https://fomantic-ui.com/images/wireframe/image.png" />
          </div>
          <div class="content">
            <p class="header">
              {{ file.code }}
              <span v-if="file.print_ready" class="ui green label">
                Print ready
              </span>
              <span v-else class="ui blue label">
                Needs conversion
              </span>
            </p>
            <div class="meta">
              <p>{{ file.digitizing ? "Digitizing" : "Vector" }}</p>
              <p>{{ file.filename_original }}</p>
            </div>
          </div>
          <div
            class="ui bottom attached progress"
            :data-percent="file.progress"
          >
            <div class="bar"></div>
          </div>
        </div>
      </div>

      <order-new-artwork
        :editing="editing"
        :order="order"
        @uploading="onArtFileUploading"
        @uploaded="onArtFileUploaded"
        @proof-added="order.needs_proof = true"
      ></order-new-artwork>
    </div>

    <div v-if="$route.params.id !== 'new' && editing" class="ui raised segment">
      <h1>Add Items</h1>
      <div v-if="order?.needs_proof" class="segment-button-container">
        <div
          @click="onGarmentAddClick"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label">
              GARMENT
            </div>
          </div>
        </div>
        <div
          @click="onPromotionalProductAddClick"
          class="ui segment-button raised segment"
        >
          <div class="ui small statistic">
            <div class="label">
              PROMOTIONAL PRODUCT
            </div>
          </div>
        </div>
      </div>
      <p v-else>To add items, first add the proof job.</p>
    </div>

    <template v-if="order">
      <div
        v-for="(garment, index) in order.GarmentOrder"
        :key="index"
        class="ui raised segment"
      >
        <div class="ui text menu">
          <div class="active item">
            <h1>Garment Item #{{ index + 1 }}</h1>
          </div>
          <div v-if="editing" class="ui right dropdown item">
            <div @click="onGarmentRemoveClick(garment)" class="ui black button">
              Remove item
            </div>
          </div>
        </div>
        <div class="ui form">
          <div class="fields">
            <div class="field">
              <label>Manufacturer</label>
              <p v-if="!editing">{{ garment.manufacturer_name }}</p>
              <select class="ui initialized dropdown" v-else v-model="garment.manufacturer">
                <option
                  v-for="manufacturer in manufacturers"
                  :key="manufacturer.id"
                  :value="manufacturer.id"
                  >{{ manufacturer.name }}</option
                >
              </select>
            </div>
            <div class="field">
              <label>SKU</label>
              <p v-if="!editing">{{ garment.sku }}</p>
              <input v-else v-model="garment.sku" />
            </div>
            <div class="field">
              <label>Item Color</label>
              <p v-if="!editing">{{ garment.colors }}</p>
              <input v-else v-model="garment.colors" />
            </div>
          </div>
        </div>
        <br />
        <div
          v-for="(imprint, imprint_index) in garment.GarmentImprintGarment"
          :key="imprint_index"
        >
          <div class="ui form">
            <div class="fields">
              <div class="field">
                <label>Decoration #{{ imprint_index + 1 }}</label>
                <p v-if="!editing">{{ imprint.location }}</p>
                <select
                  v-else
                  v-model="imprint.location"
                  name="skills"
                  class="ui fluid initialized allowadditions search dropdown"
                >
                  <option value="">Location</option>
                  <option>Left chest</option>
                  <option>Right chest</option>
                  <option>Full front</option>
                  <option>Full back</option>
                  <option>Back neck</option>
                </select>
              </div>
              <div class="field">
                <label>Type</label>
                <p v-if="!editing">{{ IMPRINT_TYPES[imprint.type] }}</p>
                <select v-else v-model="imprint.type" @change="imprint.artwork = null; imprint.colorways = '[]';" class="ui initialized dropdown">
                  <option
                    v-for="(name, value) in IMPRINT_TYPES"
                    :key="value"
                    :value="value"
                    >{{ name }}</option
                  >
                </select>
              </div>
              <div class="field">
                <label>Art</label>
                <p
                  v-if="
                    !editing && artworkFromId(imprint.artwork).filename_original"
                >
                  {{ artworkFromId(imprint.artwork).filename_original }}
                </p>
                <p v-else-if="!editing">
                  {{ artworkFromId(imprint.artwork).code }}
                </p>
                <select v-else v-model="imprint.artwork">
                  <option
                    v-for="artwork in getArtworksForType(imprint.type)"
                    :key="artwork.id"
                    :value="artwork.id"
                  >
                    {{ artwork.code }}
                    {{
                      artwork.creativeart
                        ? ""
                        : `(${artwork.filename_original.slice(0, 10)})`
                    }}
                  </option>
                </select>
                <img v-if="artworkFromId(imprint.artwork) && artworkFromId(imprint.artwork).digitizing?.image_file" :src="artworkFromId(imprint.artwork).digitizing.image_file" style="max-width: 50%;" />
              </div>
              <div class="four wide field">
                <label>Colorways</label>
                <order-colorways v-model="imprint.colorways" :type="imprint.type" :editing="editing" />
                <div v-if="editing"
                  v-show="JSON.parse(imprint.colorways).length === 0 || !(artworkFromId(imprint.artwork)?.vinyl?.color === 'S' || artworkFromId(imprint.artwork)?.namesdigitizing || artworkFromId(imprint.artwork)?.namesvinyl)"
                  :ref="`garment-${index}-${imprint_index}-colorways`" class="ui fluid search selection color dropdown">
                  <input type="hidden" name="country">
                  <i class="dropdown icon"></i>
                  <div class="default text">Select colors</div>
                  <div class="menu">
                    <div v-for="color in getColorsForType(imprint, imprint.type, 'garment', index, imprint_index)" :key="color.key"
                      class="item" :data-value="color.id">
                      <div class="ui label" :style="`background-color: ${color.RGB}; border-color: ${color.RGB};`"></div>
                      {{ color.name }} <template v-if="color.PMS">(PMS {{ color.PMS }})</template>
                    </div>
                  </div>
                </div>
                <p v-show="editing && artworkFromId(imprint.artwork)?.namesdigitizing && JSON.parse(imprint.colorways).length === 1">(Single color emboidery)</p>
                <p v-show="editing && artworkFromId(imprint.artwork)?.namesvinyl && JSON.parse(imprint.colorways).length === 1">(Single color vinyl)</p>
                <p v-show="editing && artworkFromId(imprint.artwork)?.vinyl?.color === 'S' && JSON.parse(imprint.colorways).length === 1">(Single color vinyl)</p>
              </div>
              <div v-if="editing" class="field">
                <label>&nbsp;</label>
                <div
                  @click="onGarmentImprintRemoveClick(garment, imprint)"
                  class="ui black button"
                >
                  Remove imprint
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="editing"
          @click="onGarmentImprintAddClick(garment)"
          class="ui blue button"
        >
          Add Decoration
        </div>
      </div>

      <div
        v-for="(promotionalProduct, index) in order.PromotionalProductOrder"
        :key="index"
        class="ui raised segment"
      >
        <div class="ui text menu">
          <div class="active item">
            <h1>Promotional Product Item #{{ index + 1 }}</h1>
          </div>
          <div v-if="editing" class="ui right dropdown item">
            <div
              @click="onPromotionalProductRemoveClick(promotionalProduct)"
              class="ui black button"
            >
              Remove item
            </div>
          </div>
        </div>
        <div class="ui form">
          <div class="fields">
            <div class="ten wide field">
              <label>Product URL from Vendor</label>
              <p v-if="!editing">{{ promotionalProduct.url }}</p>
              <input v-else v-model="promotionalProduct.url" />
            </div>
            <div class="six wide field">
              <label>Item Color</label>
              <p v-if="!editing">{{ promotionalProduct.color }}</p>
              <input v-else v-model="promotionalProduct.color" />
            </div>
          </div>
          <div class="field">
            <label>Decoration Type and Details</label>
            <p v-if="!editing">{{ promotionalProduct.description }}</p>
            <input v-else v-model="promotionalProduct.description" />
          </div>
        </div>
        <br />
        <div
          v-for="(imprint,
          imprint_index) in promotionalProduct.ProductImprintPromotionalProduct"
          :key="imprint_index"
        >
          <div class="ui form">
            <div class="fields">
              <div class="field">
                <label>Decoration #{{ imprint_index + 1 }}</label>
                <p v-if="!editing">{{ imprint.location }}</p>
                <input v-else v-model="imprint.location" />
              </div>
              <div class="field">
                <label>Art</label>
                <p v-if="!editing">
                  {{ artworkFromId(imprint.artwork).code }}
                </p>
                <select v-else v-model="imprint.artwork" class="ui dropdown">
                  <option
                    v-for="artwork in order.ArtworkOrder.filter(v => v.vector || v.creativeart)"
                    :key="artwork.id"
                    :value="artwork.id"
                  >
                    {{ artwork.code }}
                    ({{ artwork.filename_original?.slice(0, 10) }})
                  </option>
                </select>
              </div>
              <div class="four wide field">
                <label>Colorways</label>
                <order-colorways v-model="imprint.colorways" type="SC" :editing="editing" />
                <div v-if="editing" :ref="`promo-${index}-${imprint_index}-colorways`" class="ui fluid search selection color dropdown">
                  <input type="hidden" name="country">
                  <i class="dropdown icon"></i>
                  <div class="default text">Select colors</div>
                  <div class="menu">
                    <div v-for="color in getColorsForType(imprint, 'SC', 'promo', index, imprint_index)" :key="color.key"
                      class="item" :data-value="color.id">
                      <div class="ui label" :style="`background-color: ${color.RGB}; border-color: ${color.RGB};`"></div>
                      {{ color.name }} <template v-if="color.PMS">(PMS {{ color.PMS }})</template>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="editing" class="field">
                <label>&nbsp;</label>
                <div
                  @click="
                    onPromotionalProductImprintRemoveClick(
                      promotionalProduct,
                      imprint
                    )
                  "
                  class="ui black button"
                >
                  Remove imprint
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="editing"
          @click="onPromotionalProductImprintAddClick(promotionalProduct)"
          class="ui blue button"
        >
          Add Decoration
        </div>
      </div>

      <order-pricing v-if="$route.params.id !== 'new'" :order="order" />
    </template>
  </div>
</template>

<script>
import MenuItem from "@/components/MenuItem.vue";
import OrderColorways from "@/components/OrderColorways.vue";
import OrderHeader from "@/components/OrderHeader.vue";
import OrderNewArtwork from "@/components/OrderNewArtwork.vue";
import OrderPricing from "@/components/OrderPricing.vue";
import formErrorHandler from "@/utils/formErrorHandler";
import { mapActions, mapState } from "vuex";

const $ = window.jQuery;

export default {
  components: {
    MenuItem,
    OrderColorways,
    OrderHeader,
    OrderNewArtwork,
    OrderPricing
  },

  data() {
    return {
      files: [],

      order: null,

      loading: {},

      editing: false,

      IMPRINT_TYPES: {
        EM: "Embroidery",
        SC: "Screenprint",
        VI: "Vinyl",
        ND: "Names - Embroidery",
        NV: "Names - Vinyl"
      }
    };
  },

  computed: {
    artworks() {
      if (this.order?.status === "DR") {
        return this.order?.ArtworkOrder.filter(a => !a.creativeart);
      }
      if (this.order?.status === "FI") {
        return this.order?.ArtworkOrder.filter(a => a.is_enabled);
      }
      return this.order?.ArtworkOrder.filter(
        a => !a.creativeart && a.is_active
      );
    },

    creative_arts() {
      if (this.order?.status === "DR") {
        return this.order?.ArtworkOrder.filter(a => a.creativeart);
      }
      return this.order?.ArtworkOrder.filter(a => a.creativeart && a.is_active);
    },

    canProceed() {
      return (
        this.order?.ArtworkOrder.filter(a => a.creativeart).length > 0 ||
        this.order?.needs_proof
      );
    },

    canCreate() {
      return (
        !this.editing &&
        this.order?.status === "DR" &&
        this.order?.ArtworkOrder.filter(a => a.creativeart && a.is_active).length > 0
      );
    },

    canProof() {
      return (
        !this.editing &&
        this.order?.status === "DR" &&
        this.order?.ArtworkOrder.filter(a => a.creativeart && a.is_active)
          .length === 0 &&
        this.order?.needs_proof
      );
    },

    isLoading() {
      return Object.keys(this.loading).reduce(
        (acc, v) => acc || this.loading[v],
        false
      );
    },

    ...mapState([
      "currentBusinessId",
      "manufacturers",
      "inks",
      "threads",
      "vinyls",
      "orders",
      "profile",
      "users"
    ])
  },

  methods: {
    changeColorway(imprint, color) {
      imprint.dontfire = true;

      let colorways = JSON.parse(imprint.colorways);
      colorways.push(color);
      imprint.colorways = JSON.stringify(colorways);
    },

    getColorsForType(imprint, type, item, index, imprint_index) {
      let element = this.$refs[`${item}-${index}-${imprint_index}-colorways`];

      if (element) {
        this.$nextTick(() => {
          $(element).dropdown({
            allowAdditions: true,
            fullTextSearch: true,
            onChange(value) {
              if (value && !imprint.dontfire) {
                vm.changeColorway(imprint, value);
              } else {
                delete imprint.dontfire
              }
              $(this).dropdown('clear', true);
            }
          });
        });
      }

      let vm = this;
      switch (type) {
        case "SC":
          return this.inks;
        case "EM":
          return this.threads;
        case "VI":
          return this.vinyls;
        case "ND":
          return this.threads;
        case "NV":
          return this.vinyls;
      }
    },

    onInspirationalFileAdd(creative_art, input) {
      const file = this.$refs[input].files[0]

      let formData = new FormData();
      formData.append("filename", file);
      formData.append("filename_original", file.name);
      formData.append("order", creative_art.order);

      const url = `/api/v1/creative-arts/${creative_art.id}/inspirational-reference/`
      this.$http
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(async () => {
          await this.loadOrder(this.$route.params.id);
          this.order.ArtworkOrder = this.orders.find(
            o => o.id === this.$route.params.id
          ).ArtworkOrder;
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    getArtworksForType(imprintType) {
      switch (imprintType) {
        case "EM":
          return [
            ...this.order.ArtworkOrder.filter(
              a =>
                a.digitizing ||
                (a.creativeart &&
                  (a.creativeart.needs_digitizing_h ||
                    a.creativeart.needs_digitizing_s ||
                    a.creativeart.needs_digitizing_m ||
                    a.creativeart.needs_digitizing_l ||
                    a.creativeart.needs_digitizing_xl))
            )
          ];
        case "SC":
          return this.order.ArtworkOrder.filter(o => o.vector || o.creativeart);
        case "VI":
          return this.order.ArtworkOrder.filter(o => o.vinyl);
        case "ND":
          return this.order.ArtworkOrder.filter(o => o.namesdigitizing);
        case "NV":
          return this.order.ArtworkOrder.filter(o => o.namesvinyl);
        default:
          return [];
      }
    },

    artworkImage(link) {
      if (
        link.toLowerCase().endsWith(".png") ||
        link.toLowerCase().endsWith(".jpg") ||
        link.toLowerCase().endsWith(".jpeg")
      ) {
        return link;
      }
      return "https://fomantic-ui.com/images/wireframe/image.png";
    },

    artworkLink(link) {
      return process.env.NODE_ENV === "development"
        ? link
        : `https://api.om.pixelshive.com:8000${link}`;
    },

    artworkFromId(artwork_id) {
      return this.order.ArtworkOrder.find(a => a.id === artwork_id);
    },

    edit() {
      this.editing = true;

      if (this.$route.params.id !== "new") {
        this.order.customer_uuid = this.order.customer;
        this.order.customer = this.order.customer_email;
      }
    },

    discard() {
      if (window.confirm("Are you sure you want to discard all changes?")) {
        location.reload();
      }
    },

    getConvertedFrom(artwork) {
      return this.order.ArtworkOrder.find(a => a.id === artwork.converted_from);
    },

    onCreateClick() {
      this.$http
        .patch("api/v1/orders/" + this.order.id + "/create/")
        .then(() => {
          location.reload();
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onProofClick() {
      this.$http
        .patch("api/v1/orders/" + this.order.id + "/production/")
        .then(() => {
          location.reload();
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onCreativeDisableClick(creativeArt) {
      this.$http
        .patch("api/v1/creative-arts/" + creativeArt.id + "/disable/")
        .then(async () => {
          await this.loadOrder(this.$route.params.id);
          this.order.ArtworkOrder = this.orders.find(
            o => o.id === this.$route.params.id
          ).ArtworkOrder;
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onCreativeEnableClick(creativeArt) {
      this.$http
        .patch("api/v1/creative-arts/" + creativeArt.id + "/enable/")
        .then(async () => {
          await this.loadOrder(this.$route.params.id);
          this.order.ArtworkOrder = this.orders.find(
            o => o.id === this.$route.params.id
          ).ArtworkOrder;
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onInspirationalReferenceDisableClick(reference) {
      this.$http
        .patch("api/v1/inspirational-references/" + reference.id + "/disable/")
        .then(async () => {
          await this.loadOrder(this.$route.params.id);
          this.order.ArtworkOrder = this.orders.find(
            o => o.id === this.$route.params.id
          ).ArtworkOrder;
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onInspirationalReferenceEnableClick(reference) {
      this.$http
        .patch("api/v1/inspirational-references/" + reference.id + "/enable/")
        .then(async () => {
          await this.loadOrder(this.$route.params.id);
          this.order.ArtworkOrder = this.orders.find(
            o => o.id === this.$route.params.id
          ).ArtworkOrder;
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onCreativeApproveClick(creativeArt) {
      this.$http
        .patch("api/v1/creative-arts/" + creativeArt.id + "/approve/")
        .then(async () => {
          await this.loadOrder(this.$route.params.id);
          this.order.ArtworkOrder = this.orders.find(
            o => o.id === this.$route.params.id
          ).ArtworkOrder;
          if (
            !this.order.ArtworkOrder.find(
              ca =>
                ca.creative_art &&
                ca.is_active &&
                (ca.approved === null || ca.approved === false)
            )
          ) {
            location.reload();
          }
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });
    },

    onCreativeRejectClick(creativeArt) {
      const rejection_reason = window.prompt(
        "Please input a reason to reject this creative art."
      );
      if (rejection_reason) {
        this.$http
          .patch("api/v1/creative-arts/" + creativeArt.id + "/reject/", {
            rejection_reason
          })
          .then(() => {
            location.reload();
          })
          .catch(error => {
            formErrorHandler("orderForm", error);
          });
      } else if (rejection_reason !== null) {
        window.alert("You must provide a reason.");
      }
    },

    onArtworkDisableClick(artwork) {
      if (artwork.vector) {
        this.$http
          .patch("api/v1/vectors/" + artwork.id + "/disable/")
          .then(async () => {
            await this.loadOrder(this.$route.params.id);
            this.order.ArtworkOrder = this.orders.find(
              o => o.id === this.$route.params.id
            ).ArtworkOrder;
          })
          .catch(error => {
            formErrorHandler("orderForm", error);
          });
      } else if (artwork.digitizing) {
        this.$http
          .patch("api/v1/digitizings/" + artwork.id + "/disable/")
          .then(async () => {
            await this.loadOrder(this.$route.params.id);
            this.order.ArtworkOrder = this.orders.find(
              o => o.id === this.$route.params.id
            ).ArtworkOrder;
          })
          .catch(error => {
            formErrorHandler("orderForm", error);
          });
      }
    },

    onArtworkEnableClick(artwork) {
      if (artwork.vector) {
        this.$http
          .patch("api/v1/vectors/" + artwork.id + "/enable/")
          .then(async () => {
            await this.loadOrder(this.$route.params.id);
            this.order.ArtworkOrder = this.orders.find(
              o => o.id === this.$route.params.id
            ).ArtworkOrder;
          })
          .catch(error => {
            formErrorHandler("orderForm", error);
          });
      } else if (artwork.digitizing) {
        this.$http
          .patch("api/v1/digitizings/" + artwork.id + "/enable/")
          .then(async () => {
            await this.loadOrder(this.$route.params.id);
            this.order.ArtworkOrder = this.orders.find(
              o => o.id === this.$route.params.id
            ).ArtworkOrder;
          })
          .catch(error => {
            formErrorHandler("orderForm", error);
          });
      }
    },

    onArtworkRejectClick(artwork) {
      const rejection_reason = window.prompt(
        "Please input a reason to reject this artwork."
      );

      let class_path;
      if (artwork.vector) {
        class_path = "vectors";
      } else if (artwork.digitizing) {
        class_path = "digitizings";
      }

      if (rejection_reason) {
        this.$http
          .patch(`api/v1/${class_path}/${artwork.id}/reject/`, {
            rejection_reason
          })
          .then(() => {
            location.reload();
          })
          .catch(error => {
            formErrorHandler("orderForm", error);
          });
      } else if (rejection_reason !== null) {
        window.alert("You must provide a reason.");
      }
    },

    async onArtFileUploaded() {
      await this.loadOrder(this.$route.params.id);
      this.order.ArtworkOrder = this.orders.find(
        o => o.id === this.$route.params.id
      ).ArtworkOrder;
      this.files = [];
    },

    async onArtFileUploading(file) {
      this.files.push(file);
    },

    onGarmentAddClick() {
      this.order.GarmentOrder.push({
        GarmentImprintGarment: []
      });
    },

    onGarmentRemoveClick(garment) {
      this.order.GarmentOrder = this.order.GarmentOrder.filter(
        g => g !== garment
      );
    },

    onGarmentImprintAddClick(garment) {
      garment.GarmentImprintGarment.push({
        artwork: null,
        colorways: "[]",
        location: null
      });
      this.$nextTick(() => {
        $(".ui.allowadditions.dropdown").dropdown({
          allowAdditions: true
        });
      });
    },

    onGarmentImprintRemoveClick(garment, imprint) {
      garment.GarmentImprintGarment = garment.GarmentImprintGarment.filter(
        i => i !== imprint
      );
    },

    onPromotionalProductAddClick() {
      this.order.PromotionalProductOrder.push({
        ProductImprintPromotionalProduct: []
      });
    },

    onPromotionalProductRemoveClick(promotionalProduct) {
      this.order.PromotionalProductOrder = this.order.PromotionalProductOrder.filter(
        p => p !== promotionalProduct
      );
    },

    onPromotionalProductImprintAddClick(promotionalProduct) {
      promotionalProduct.ProductImprintPromotionalProduct.push({
        artwork: null,
        colorways: "[]",
        location: null
      });
      this.$nextTick(() => {
        $(".ui.allowadditions.dropdown").dropdown({
          allowAdditions: true
        });
      });
    },

    onPromotionalProductImprintRemoveClick(promotionalProduct, imprint) {
      promotionalProduct.ProductImprintPromotionalProduct = promotionalProduct.ProductImprintPromotionalProduct.filter(
        i => i !== imprint
      );
    },

    async submit() {
      $(".field.error").removeClass("error");
      if (!$("#orderForm").form("validate form")) {
        return;
      }

      if (this.$route.params.id === "new") {
        try {
          let ref_seq;
          if (
            this.order.reference_number.indexOf("-") === -1 &&
            this.order.reference_number.indexOf("/") === -1 &&
            this.order.reference_number.indexOf(".") === -1
          ) {
            throw {
              response: {
                status: 400,
                data: {
                  reference_number: ["A sequence number must be provided."]
                }
              }
            };
          } else {
            if (this.order.reference_number.indexOf("-")) {
              ref_seq = this.order.reference_number.split("-");
            } else if (this.order.reference_number.indexOf("/")) {
              ref_seq = this.order.reference_number.split("/");
            } else {
              ref_seq = this.order.reference_number.split(".");
            }
          }
          
          let response = await this.$http.post("api/v1/orders/", {
            business: this.currentBusinessId,
            sequence: ref_seq[1],
            ...this.order,
            reference_number: ref_seq[0]
          });
          this.$router.push({
            name: "Order",
            params: { id: response.data.id },
            query: { editing: true }
          });
          window.setTimeout(() => {
            location.reload();
          }, 100);
        } catch (error) {
          formErrorHandler("orderForm", error);
        }
      } else {
        try {
          let payload = JSON.parse(JSON.stringify(this.order));

          payload.customer = payload.customer_uuid;
          delete payload.ArtworkOrder;

          await this.$http.patch(`api/v1/orders/${this.order.id}/`, payload);
          location.reload();
        } catch (error) {
          formErrorHandler("orderForm", error);
        }
      }
    },

    submitInspirationalReference(field, files) {
      const file = files[0];

      let formData = new FormData();
      formData.append("filename", file);
      formData.append("filename_original", file.name);

      let url =
        "api/v1/creative-arts/be924fc4-4df4-4cc5-ae96-0b742d9fd5fb/inspirational-reference/";
      this.$http
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        });

      $(`#${field}`).val("");
    },

    ...mapActions(["loadManufacturers", "loadInks", "loadThreads", "loadVinyls", "loadOrder", "loadUsers"])
  },

  watch: {
    editing(editing) {
      this.loadManufacturers({ query: "?is_active=1&page_size=1000" });

      if (editing) {
        if (this.profile.role === "A") {
          this.loadUsers().then(() => {
            if (this.order.sales_rep) {
              $("#sales_rep").dropdown("set selected", this.order.sales_rep);
            }
          });
        }
        this.$nextTick(() => {
          $("#orderForm").form({
            fields: {
              customer: ["email"],
              reference_number: "empty",
              sales_rep: "empty",
              sequence: "empty"
            }
          });
        });

        this.$nextTick(() => {
          let vm = this;
          $(".ui.initialized.dropdown").dropdown();
          $(".ui.checkbox").checkbox();
          var today = new Date();
          $("#due_date").calendar({
            type: "date",
            initialDate: this.order.date,
            minDate: new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + 1
            ),
            onChange(date) {
              vm.order.date = date;
            }
          });
        });
      }
    },

    profile(profile) {
      if (profile.role !== "A") {
        this.order.sales_rep = this.profile.id;
      }
    }
  },

  async created() {
    this.loadInks({ query: "?is_active=1&page_size=1000" });
    this.loadThreads({ query: "?is_active=1&page_size=1000" });
    this.loadVinyls({ query: "?is_active=1&page_size=1000" });

    if (this.$route.params.id !== "new") {
      await this.loadOrder(this.$route.params.id);
      this.order = this.orders.find(o => o.id === this.$route.params.id);
      if (this.$route.query.editing === "true") {
        this.edit();
        this.$router.push({
          query: {}
        });
      }
    } else {
      this.editing = true;
      this.loadUsers();
      this.order = {
        ArtworkOrder: [],
        customer: null,
        description: "",
        GarmentOrder: [],
        PromotionalProductOrder: [],
        reference_number: null,
        sales_rep: null,
        template: "BR",
        needs_proof: true
      };

      if (this.profile?.role !== "A") {
        this.order.sales_rep = this.profile.id;
      }
    }
  }
};
</script>

<style scoped>
.ui.floating.text.menu {
  position: fixed;
  right: 10em;
  z-index: 1000;
}

.segment-button-container {
  display: flex;
}

.segment-button {
  align-items: center;
  display: flex;
  width: 10em;
  height: 10em;
  margin-top: 0;
  margin-right: 3em;
  cursor: pointer;
}

.segment-button > div {
  margin: 0 auto;
}

.ui.card {
  width: 12em;
}

.card.disabled .image {
  filter: brightness(0.5);
}

.card.disabled .content {
  filter: brightness(0.5);
  background-color: lightgray;
}
</style>
