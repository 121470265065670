<template>
  <div id="orderForm" class="ui form raised segment">
    <h1 v-if="!editing || this.$route.params.id !== 'new'">
      Order {{ order?.reference_number }}-{{ order?.sequence }}
      <span v-if="order?.status === 'DR'" class="ui big label">DRAFT</span>
      <span
        v-else-if="order?.creative_redo && order?.status === 'CR'"
        class="ui big orange label"
      >
        CREATING <i class="ui warning icon" />
      </span>
      <span v-else-if="order?.status === 'CR'" class="ui big green label">
        CREATING
      </span>
      <span v-else-if="order?.status === 'CA'" class="ui big blue label">
        CREATIVE APPROVAL
      </span>
      <span
        v-else-if="order?.proof_redo && order?.status === 'PR'"
        class="ui big orange label"
      >
        PROOFING <i class="ui warning icon" />
      </span>
      <span v-else-if="order?.status === 'PR'" class="ui big green label">
        PROOFING
      </span>
      <span v-else-if="order?.status === 'PA'" class="ui big green label">
        PROOF APPROVAL
      </span>
      <span
        v-else-if="order?.art_redo && order?.status === 'CO'"
        class="ui big orange label"
      >
        CONVERTING <i class="ui warning icon" />
      </span>
      <span v-else-if="order?.status === 'CO'" class="ui big green label">
        CONVERTING
      </span>
      <span v-else-if="order?.status === 'FI'" class="ui big black label">
        FINISHED
      </span>
    </h1>
    <h1 v-else>New Order</h1>
    <div class="ui error message"></div>
    <div>
      <div class="fields">
        <div
          v-if="editing && this.$route.params.id === 'new'"
          class="four wide field"
        >
          <label>Job Number with Sequence Number</label>
          <div class="field">
            <input
              name="reference_number"
              placeholder="Job Number with Sequence Number"
              :value="order.reference_number"
              @input="
                $emit('update:order', {
                  ...order,
                  reference_number: $event.target.value
                })"
            />
          </div>
        </div>
        <!-- <div class="four wide field">
          <label>Due Date</label>
          <p v-if="!editing">
            {{
              DateTime.fromISO(order?.date).toLocaleString(DateTime.DATE_MED)
            }}
          </p>
          <div v-else class="ui calendar" id="due_date">
            <div class="ui input left icon">
              <i class="calendar icon"></i>
              <input type="text" placeholder="Due Date" />
            </div>
          </div>
        </div> -->
        <div class="four wide field">
          <label>Priority</label>
          <p v-if="!editing">{{ PRIORITIES[order?.rush] }}</p>
          <select
            v-else
            :value="order.rush"
            class="ui selection initialized dropdown"
            @change="
              $emit('update:order', {
                ...order,
                rush: $event.target.value
              })"
          >
            <option
              v-for="(name, value) in PRIORITIES"
              :key="value"
              :value="value"
              >{{ name }}</option
            >
          </select>
          <p style="color: darkgray; font-size: 0.8em;">Turnaround times: 24h (Standard), 8h (Rush)</p>
        </div>
      </div>
      <div class="fields">
        <div v-if="profile?.role === 'A'" class="four wide field">
          <label>Sales Rep</label>
          <p v-if="!editing">{{ order?.sales_rep_email }}</p>
          <select
            class="ui dropdown"
            id="sales_rep"
            name="sales_rep"
            v-else
            :value="order.sales_rep"
            @change="
              $emit('update:order', {
                ...order,
                sales_rep: $event.target.value
              })"
          >
            <option></option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.user.email }}
            </option>
          </select>
        </div>
        <div class="four wide field">
          <label>Customer Email for Proof Approval</label>
          <p v-if="!editing">{{ order?.customer_email }}</p>
          <input
            name="customer"
            v-else
            :value="order.customer"
            @input="
              $emit('update:order', { ...order, customer: $event.target.value })"
          />
          <p v-if="editing" style="color: darkgray; font-size: 0.8em;">Please note: All creative artwork is sent directly to the Sales Rep.</p>
        </div>
        <div class="two wide field">
          <label>Template</label>
          <p v-if="!editing">{{ TEMPLATES[order?.template] }}</p>
          <select
            v-else
            :value="order.template"
            @change="
              $emit('update:order', {
                ...order,
                template: $event.target.value
              })
            "
            class="ui selection initialized dropdown"
          >
            <option
              v-for="(name, value) in TEMPLATES"
              :key="value"
              :value="value"
              >{{ name }}</option
            >
          </select>
        </div>
      </div>
      <div class="field">
        <h2>Notes</h2>

        <textarea
          v-if="editing && $route.params.id === 'new'"
          :value="order.description"
          @input="
            $emit('update:order', {
              ...order,
              description: $event.target.value
            })
          "
          rows="3"
        />

        <div v-if="$route.params.id !== 'new'" class="ui comments">
          <div v-if="order?.description" class="comment">
            <div class="content">
              <span class="author">
                {{ order.created_by_email }}
              </span>
              <div class="metadata">
                <div class="date">{{ DateTime.fromISO(order.created_at, { zone: 'utc' }).toRelative() }}</div>
              </div>
              <div class="text">
                {{ order.description }}
              </div>
            </div>
          </div>

          <div v-for="note in order?.notes" :key="note.id" class="comment">
            <a v-if="note.filename" :href="note.filename" target="_blank" class="avatar">
              <img :src="note.filename">
            </a>
            <div class="content">
              <span class="author">
                <template v-if="note.author === 'PH'">
                  Pixel's Hive
                </template>
                <template v-else-if="note.author === 'CU'">
                  {{ order.customer_email }}
                </template>
                <template v-if="note.author === 'BU'">
                  {{ note.sales_rep_email }}
                </template>
              </span>
              <div class="metadata">
                <div class="date">{{ DateTime.fromISO(note.date, { zone: 'utc' }).toRelative() }}</div>
              </div>
              <div class="text">
                {{ note.message }}
              </div>
            </div>
          </div>
        </div>
        <textarea
          v-if="!editing && $route.query.id !== 'new'"
          v-model="noteMessage"
          :disabled="loading.noteSubmission"
          placeholder="Add a new note..."
          rows="3"
        />
        <div class="field">
        <label>Attachment</label>
        <input
          id="attachmentFile"
          name="attachmentFile"
          type="file"
        />
      </div>
        <button
          v-if="!editing && $route.query.id !== 'new'"
          :class="{ 'loading disabled': loading.noteSubmission }"
          @click="submitNote"
          class="ui primary button">
          <i class="send icon" /> Send
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import formErrorHandler from "@/utils/formErrorHandler";
import { DateTime } from "luxon";
import { mapActions, mapState } from "vuex";

const $ = window.jQuery;

export default {
  props: {
    editing: Boolean,
    order: Object
  },

  data() {
    return {
      noteMessage: '',

      loading: {
        noteSubmission: false,
      },

      PRIORITIES: {
        S: "Standard",
        R: "Rush"
      },

      TEMPLATES: {
        BL: "Blind",
        BR: "Branded"
      },

      DateTime
    };
  },

  computed: {
    ...mapState(["profile", "users"])
  },

  methods: {
    submitNote() {
      const attachments = document.getElementById('attachmentFile').files;
      const attachment = attachments[0];

      let formData = new FormData();
      formData.append("message", this.noteMessage);
      formData.append("filename", attachment);
      this.loading.noteSubmission = true;
      this.$http.post("api/v1/orders/" + this.order.id + "/note/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.noteMessage = '';

          let order = response.data
          order.customer_uuid = order.customer;
          order.customer = order.customer_email;
          this.$emit('update:order', order)
        })
        .catch(error => {
          formErrorHandler("orderForm", error);
        })
        .finally(() => {
          $(`#attachmentFile`).val("");
          this.loading.noteSubmission = false;
        })
    },

    ...mapActions(['loadOrder'])
  },

  mounted() {
    $(".ui.initialized.dropdown").dropdown();
  }
};
</script>

<style scoped>
pre {
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  white-space: pre-line;
  word-break: break-word;
}
</style>
